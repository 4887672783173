import Box from '@rexlabs/box';
import { MaximumPayableCommissionFormula } from 'features/commission-worksheet/components/max-payable-commission-formula';
import { RecordListTable } from 'components/record-list-screen/table';
import { Body } from 'components/text/body';
import { formatCurrency } from 'utils/formatters';
import dayjs from 'dayjs';
import AlertBanner from 'view/components/alert-banner';
import { Link } from 'components/text/link';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useRegion } from 'hooks/use-region';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import {
  AgentAllocation,
  CommissionTier,
  CommissionWorksheetItem
} from 'features/commission-worksheet/types/commission-worksheet';
import { UpstreamCommissionSummary } from 'data/models/entities/agent-commissions';
import { ColumnConfig } from 'components/record-list-screen/types';
import { useDialog } from 'hooks/use-dialog';
import PercentageInputCell from './table-cells/percentage-input-cell';
import { getSlidingScaleCommission } from 'features/commission-worksheet/utils/get-sliding-scale-commission';
import { sumBy } from 'lodash';
import { stripPrefixFromProperties } from 'shared/utils/prefix-hell';
import { ContractsItem } from 'features/listings/data/contracts/types';
import Checkbox from 'src/view/components/input/checkbox';
import TierRangeCell from './table-cells/tier-range-cell';
import TierAmountCell from './table-cells/tier-amount-cell';

const LISTING_REACHED_CALCULATION_DATE_MESSAGE =
  'This listing has reached it’s calculation date and the amount has been based on the agent’s earnings to date at that point in time.';

const TOTAL_COMMISSION_ESTIMATE_BASIS_MESSAGE =
  'The estimated total commission amount has been calculated based on the ' +
  "agent's current earnings to date, placing them in the tiers above.";

const styles = StyleSheet({
  total: {
    borderBottom: `6px double #dbdbd6`
  }
});

interface AgentSlidingScaleCommissionsProps {
  worksheet: CommissionWorksheetItem;
  worksheetVersionId: string;
  agentAllocation: AgentAllocation;
  commissionSummary: UpstreamCommissionSummary | null;
  distributableCommissions: number;
  availableCommission: number;
  onChange: (name: string, value: unknown, silent?: boolean) => void;
}

const cellStyle = {
  verticalAlign: 'top',
  padding: '14px 10px 10px'
};

export const AgentSlidingScaleCommissions = ({
  worksheetVersionId,
  agentAllocation,
  commissionSummary,
  distributableCommissions,
  availableCommission,
  onChange,
  worksheet
}: AgentSlidingScaleCommissionsProps) => {
  const s = useStyles(styles);
  const currencySymbol = useRegion().financial.currency.symbol;
  const commissionBreakdown = useDialog('commissionBreakdown');
  const [showBreakdown, setShowBreakdown] = useState(false);

  const mappedTiers = useMemo(() => {
    if (!commissionSummary || !agentAllocation.sliding_scale_tiers) return [];
    return getSlidingScaleCommission(
      availableCommission,
      commissionSummary.summary.total_amount,
      commissionSummary.calculation_base.id,
      agentAllocation.sliding_scale_tiers
    );
  }, [availableCommission, commissionSummary, agentAllocation]);

  const columns: ColumnConfig<CommissionTier>[] = useMemo(
    () => [
      {
        id: 'tier',
        label: 'sliding commission tier',
        forced: true,
        Cell: TierRangeCell as FC,
        cellProps: {
          showBreakdown,
          width: 270,
          style: cellStyle,
          calculationId: commissionSummary?.calculation_base.id ?? '',
          length: agentAllocation.sliding_scale_tiers?.length || 0
        }
      },
      {
        id: 'allocated',
        label: 'allocated',
        forced: true,
        selector: (row) => {
          return `${currencySymbol}${formatCurrency(row.allocated, 2)}`;
        },
        cellProps: {
          style: cellStyle,
          width: 150
        }
      },
      {
        id: 'listing_amount_percentage',
        label: 'amount to agent',
        forced: true,
        width: '30%',
        Cell: PercentageInputCell as FC,
        cellProps: {
          style: {
            verticalAlign: 'top'
          },
          fullWidth: true,
          valueKey: 'percent',
          onChange: (e: ChangeEvent<HTMLInputElement>, data) => {
            if (!commissionSummary || !agentAllocation.sliding_scale_tiers)
              return;

            // Update percent for the tier
            const tiers = agentAllocation.sliding_scale_tiers.map((tier) =>
              tier.sliding_structure_tier.id === data.sliding_structure_tier.id
                ? {
                    ...tier,
                    percent: e.target.value
                  }
                : tier
            );

            // Calculate new tier commissions
            const tierAllocations = getSlidingScaleCommission(
              availableCommission,
              commissionSummary.summary.total_amount,
              commissionSummary.calculation_base.id,
              tiers
            );

            onChange(
              'sliding_scale_tiers',
              tierAllocations.map((tier) => ({
                sliding_structure_tier: tier.sliding_structure_tier,
                amount: tier.amount,
                percent: tier.percent
              }))
            );
          }
        }
      },
      {
        id: 'amount',
        label: `${currencySymbol} amount`,
        forced: true,
        rightAlign: true,
        Cell: TierAmountCell as FC,
        cellProps: {
          justifyContentEnd: true,
          showBreakdown,
          style: cellStyle,
          width: 200
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agentAllocation, commissionSummary, showBreakdown]
  );

  const totalSlidingCommission = useMemo(() => {
    if (!agentAllocation.sliding_scale_tiers) return 0;
    return sumBy(agentAllocation.sliding_scale_tiers, (tier) =>
      Number(tier.amount)
    );
  }, [agentAllocation.sliding_scale_tiers]);

  const commissionSummaryEndDateIsTodayOrPast = useMemo(() => {
    if (!commissionSummary || !worksheet) return false;

    const contract = stripPrefixFromProperties(
      worksheet.contract
    ) as ContractsItem;

    const statusDateMapping = {
      settled: contract.date_actual_settlement,
      unconditional: contract.date_actual_unconditional,
      deposit_release: contract.date_actual_deposit_release,
      agent_comm_payment: contract.date_actual_agent_comm_payment
    };

    const relevantDate =
      statusDateMapping[commissionSummary.calculation_listing_status.id];

    const today = dayjs();

    if (relevantDate) return dayjs(relevantDate).isSameOrBefore(today);
    return false;
  }, [commissionSummary, worksheet]);

  return (
    <>
      {worksheetVersionId === '3' ? null : (
        <Box mb={15}>
          <MaximumPayableCommissionFormula
            currencySymbol={currencySymbol}
            distributableCommissions={distributableCommissions}
            agentAllocation={agentAllocation}
            availableCommission={availableCommission}
          />
        </Box>
      )}
      <Box justifyContent='space-between' alignItems='center'>
        <Body dark small semibold>
          amount to agent
        </Body>
        <Checkbox
          value={showBreakdown}
          label={'show calculation breakdown'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setShowBreakdown(e.target.checked);
          }}
        />
      </Box>
      <RecordListTable
        items={mappedTiers}
        columns={columns}
        visibleColumns={columns.map((c) => c.id)}
        setVisibleColumns={() => null}
        hasSelection={false}
        setOrderBy={() => null}
        isLoading={false}
        LoadingView={() => null}
        EmptyView={() => null}
        variant={'compact'}
        colorScheme={'light'}
      />
      <Box
        ml={'50%'}
        mb={20}
        p={'15px 10px 15px 10px'}
        justifyContent='space-between'
        alignItems='center'
        {...s('total')}
      >
        <Body dark small semibold>
          TOTAL AMOUNT
        </Body>
        <Body dark semibold>
          {currencySymbol}
          {formatCurrency(totalSlidingCommission, 2)}
        </Body>
      </Box>
      <AlertBanner
        type={commissionSummaryEndDateIsTodayOrPast ? 'disabled' : 'warning'}
        message={
          commissionSummaryEndDateIsTodayOrPast
            ? LISTING_REACHED_CALCULATION_DATE_MESSAGE
            : TOTAL_COMMISSION_ESTIMATE_BASIS_MESSAGE
        }
      >
        <Body small dark style={{ marginTop: -5 }}>
          Click below to see what listings have been included in their earnings
          to date.
        </Body>
        <Link
          small
          dark
          style={{ fontWeight: 500 }}
          onClick={() => commissionBreakdown.open({ commissionSummary })}
        >
          View commission breakdown
        </Link>
      </AlertBanner>
    </>
  );
};
