import { ColumnsConfig } from 'components/record-list-screen/types';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  ValidXeroAccount,
  WingsXero,
  XeroRexMapAccount
} from '../../types/third-party-service-xero';
import { RelatedTable } from 'view/components/record-details-screen/related-table';
import { withModel } from '@rexlabs/model-generator';
import thirdPartyServiceXeroModel from '../../data/third-party-service-xero';
import { SelectCell } from 'components/record-list-screen/cells/select-cell';
import { useErrorDialog } from 'hooks/use-error-dialog';
import SaveCancelOverlayHandler from 'src/view/components/save-cancel-overlay-handler';
import { capitalize } from 'lodash';

const InvoiceSettings = ({
  wingsXero,
  onFailed
}: {
  wingsXero: WingsXero;
  onFailed: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const errorDialog = useErrorDialog();
  const [hasChanges, setHasChanges] = useState(false);
  const updatedXeroAccounts = useRef<XeroRexMapAccount>({
    valid_xero_accounts: [],
    mapping: []
  });
  const [mappedXeroAccounts, setMappedXeroAccounts] =
    useState<XeroRexMapAccount>({
      valid_xero_accounts: [],
      mapping: []
    });

  useEffect(() => {
    wingsXero({}, 'ThirdPartyServiceXero::getAccountMapping')
      .then((res) => {
        setMappedXeroAccounts(res as XeroRexMapAccount);
        updatedXeroAccounts.current = res as XeroRexMapAccount;
        setIsLoading(false);
      })
      .catch((error) => {
        onFailed();
        setIsLoading(false);
        errorDialog.open(error as Error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAccountChanges = async () => {
    try {
      const updatedAccounts = {};

      updatedXeroAccounts.current.mapping.forEach(
        ({ rex_account, xero_account }) => {
          updatedAccounts[rex_account.id] = xero_account.id ?? null;
        }
      );

      const res = await wingsXero(
        { data: updatedAccounts },
        'ThirdPartyServiceXero::setAccountMapping'
      );
      setMappedXeroAccounts(res as XeroRexMapAccount);
      updatedXeroAccounts.current = res as XeroRexMapAccount;
      setHasChanges(false);
    } catch (error) {
      setHasChanges(false);
      updatedXeroAccounts.current = mappedXeroAccounts;
      errorDialog.open(error as Error);
    }
  };

  const onChange = ({ target }) => {
    if (!hasChanges) setHasChanges(true);
    updatedXeroAccounts.current = {
      ...updatedXeroAccounts.current,
      mapping: updatedXeroAccounts.current.mapping.map((account) => ({
        ...account,
        xero_account:
          +account.rex_account.id === +target.id
            ? target.value
              ? { id: target.value.value, text: target.label }
              : { id: null, text: null }
            : account.xero_account
      }))
    };
  };

  const columns: ColumnsConfig<ValidXeroAccount> = [
    {
      id: 'rex_account.text',
      label: 'account name',
      hidden: false,
      width: '50%'
    },
    {
      id: 'xero_account.id',
      label: 'xero account',
      Cell: SelectCell,
      cellProps: ({ rex_account: rex, xero_account: xero }) => ({
        onChange,
        options: mappedXeroAccounts.valid_xero_accounts.map(
          ({ id, text, type }) => ({
            value: id,
            label: text,
            type
          })
        ),
        groupOptions: (option) => ({
          label: capitalize(option.type),
          value: option.type
        }),
        name: rex.id,
        value: (() => {
          // Refs won't trigger a re-render so in order for us to show the updated value we need to manually find it
          // This works because table will re-render when switching page, so the table has the updated ref value
          const account = updatedXeroAccounts.current.mapping.find(
            (item) => item.rex_account.id === rex.id
          );
          if (!account) return null;
          return {
            label: account.xero_account.text,
            value: account.xero_account.id
          };
        })(),
        valueAsObject: true,
        width: '100%'
      })
    }
  ];

  const xeroAccountsMappingWithId = useMemo(() => {
    return mappedXeroAccounts.mapping.map((item) => ({
      id: item.rex_account.id,
      ...item
    }));
  }, [mappedXeroAccounts]);

  return (
    <Fragment>
      <RelatedTable
        // Manually trigger re-render of table so that when doing 'Cancel' original data will show
        key={`table.${hasChanges ? 1 : 0}`}
        items={xeroAccountsMappingWithId}
        columns={columns}
        colorScheme='dark'
        isLoading={isLoading}
      />
      <SaveCancelOverlayHandler
        isVisible={hasChanges}
        onSave={saveAccountChanges}
        onCancel={() => {
          setHasChanges(false);
          updatedXeroAccounts.current = mappedXeroAccounts;
        }}
      />
    </Fragment>
  );
};

export default withModel(thirdPartyServiceXeroModel)(InvoiceSettings);
