import _, { camelCase, omit } from 'lodash';
import {
  AddApplicationForm,
  AddApplicationPayload,
  AddEditTenantForm,
  TenancyApplicationItem,
  Tenant
} from './types';
import dayjs from 'dayjs';
import { ContactStub } from 'data/models/entities/contacts';
import { mapContactToSelectValue } from 'features/listings/dialogs/view-edit-offer/utils';
import { Region } from 'hooks/use-region';

export const dateFormat = 'YYYY-MM-DD';
export const dateFormatReadable = 'DD MMM YY';

export function formTenantToApi(tenant: AddEditTenantForm): Tenant {
  const { net_income: income } = tenant;
  return <Tenant>{
    ...omit(tenant, [
      'has_guarantor',
      'contact',
      'guarantors',
      'employment_status_id',
      'net_income_period_id'
    ]),
    contact: tenant.contact.data as ContactStub,
    guarantors:
      tenant.guarantors && tenant.guarantors.length > 0
        ? tenant.guarantors?.map((g) => ({ contact: g.data as ContactStub }))
        : [],
    employment_status: {
      id: tenant.employment_status_id,
      text: camelCase(tenant.employment_status_id)
    },
    net_income: income && parseFloat(income) > 0 ? income : null,
    net_income_period: {
      id: tenant.net_income_period_id,
      text: camelCase(tenant.net_income_period_id)
    }
  };
}

export function tenantApiToFormData(tenant: Tenant): AddEditTenantForm {
  return {
    ...omit(tenant, [
      'contact',
      'guarantors',
      'employment_status',
      'net_income_period'
    ]),
    has_guarantor: !!tenant.guarantors && tenant.guarantors.length > 0,
    contact: mapContactToSelectValue(tenant.contact),
    guarantors:
      tenant.guarantors && tenant.guarantors.length > 0
        ? tenant.guarantors.map((g) => mapContactToSelectValue(g.contact))
        : [],
    employment_status_id: tenant.employment_status.id,
    net_income_period_id: tenant.net_income_period.id
  };
}

export function apiToFormData(
  application: TenancyApplicationItem
): Partial<AddApplicationForm> {
  return {
    offer_amount: application.offer_amount?.toString(),
    offer_amount_period_id: application.offer_amount_period?.id,
    lease_type_id: application.lease_type?.id,
    agreement_type_id: application.agreement_type?.id,
    agent_id: application.agent ? application.agent.id : undefined,
    start_date: application.start_date
      ? new Date(application.start_date)
      : undefined,
    agreement_length_months: application.agreement_length_months,
    end_date: application.end_date ? new Date(application.end_date) : undefined,
    num_of_dependents:
      application.num_of_dependents === null
        ? '0'
        : application.num_of_dependents?.toString(),
    num_of_households:
      application.num_of_households === null
        ? '0'
        : application.num_of_households?.toString(),
    num_of_occupants:
      application.num_of_occupants === null
        ? '0'
        : application.num_of_occupants?.toString(),
    has_pets: !!application.has_pets,
    conditions: application.conditions,
    notes: application.notes,
    date_received: application.date_received
      ? new Date(application.date_received)
      : undefined,
    listing_application_tenants:
      application.related.listing_application_tenants.map(tenantApiToFormData)
  };
}

export function formDataToApi(
  application: AddApplicationForm,
  region: Region
): Omit<AddApplicationPayload, 'listing_id'> {
  return {
    ...(region.isUK
      ? {
          ..._.omit(application, [
            'num_of_dependents',
            'listing_application_tenants'
          ]),
          num_of_households: +application.num_of_households,
          num_of_occupants: +application.num_of_occupants
        }
      : {
          ..._.omit(application, [
            'agreement_type_id',
            'listing_application_tenants',
            'num_of_households',
            'num_of_occupants'
          ]),
          num_of_dependents: +application.num_of_dependents
        }),
    offer_amount: +application.offer_amount,
    start_date: application.start_date
      ? dayjs(application.start_date).format(dateFormat)
      : undefined,
    end_date: application.end_date
      ? dayjs(application.end_date).format(dateFormat)
      : undefined,
    date_received: application.date_received
      ? dayjs(application.date_received).format(dateFormat)
      : undefined,
    related: {
      listing_application_tenants:
        application.listing_application_tenants.map(formTenantToApi)
    }
  };
}
