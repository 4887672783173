import React from 'react';
import { MarketingCampaign } from '../data/performance';
import PaddingBox from 'src/view/components/padding-box';
import { Body } from 'components/text/body';
import { Column, Grid } from 'shared/components/grid';
import { SubHeading } from 'components/text/sub-heading';

interface SecondaryStatsProps {
  selectedPortal: MarketingCampaign;
}

const SecondaryStats = ({ selectedPortal }: SecondaryStatsProps) => {
  return selectedPortal.secondary_stats.map((secondaryStat, index) => {
    const count =
      selectedPortal.engagement_stats &&
      selectedPortal.engagement_stats.length > 0
        ? index + 1
        : index;
    return (
      <PaddingBox
        key={secondaryStat.title}
        light={count % 2 === 0}
        white={count % 2 !== 0}
        padding={'14px 25px 6px'}
      >
        <SubHeading semibold>{secondaryStat.title}</SubHeading>
        <Grid columns={2} mt={10}>
          {secondaryStat.data.map((stat) => (
            <Column
              width={1}
              key={stat.id}
              flexDirection='row'
              alignItems='flex-end'
              spacing={5}
              mb={8}
            >
              <Body small dark semibold style={{ textTransform: 'lowercase' }}>
                {stat.label}
              </Body>
              <Body dark normal>
                {stat.value}
              </Body>
            </Column>
          ))}
        </Grid>
      </PaddingBox>
    );
  });
};

export default SecondaryStats;
