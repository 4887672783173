import React, { useMemo } from 'react';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import { CommissionTier } from '../../types/commission-worksheet';
import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';

interface TierAmountCellProps extends DefaultCellProps {
  data: CommissionTier;
  showBreakdown: boolean;
}

function TierAmountCell({ data, showBreakdown, ...rest }: TierAmountCellProps) {
  const currencySymbol = useRegion().financial.currency.symbol;

  const breakdown = useMemo(() => {
    return (
      <Body small light normal regular style={{ marginTop: 4 }}>
        {Number(data.percent)}% of allocated {currencySymbol}
        {formatCurrency(data.allocated, 2)}
      </Body>
    );
  }, [data, currencySymbol]);

  return (
    <DefaultCell
      {...rest}
      value={
        <Box flexDirection='column' alignItems='flex-end'>
          <Body dark normal>
            {currencySymbol}
            {formatCurrency(data.amount, 2)}
          </Body>
          {showBreakdown && breakdown}
        </Box>
      }
    />
  );
}

export default TierAmountCell;
