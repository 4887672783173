import React, { useMemo } from 'react';
import {
  DefaultCell,
  DefaultCellProps
} from 'components/record-list-screen/cells/default';
import { CommissionTier } from '../../types/commission-worksheet';
import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'src/utils/formatters';

interface TierRangeCellProps extends DefaultCellProps {
  data: CommissionTier;
  showBreakdown: boolean;
  calculationId: string;
  length: number;
  index: number;
}

function TierRangeCell({
  data,
  showBreakdown,
  calculationId,
  length,
  index,
  ...rest
}: TierRangeCellProps) {
  const currencySymbol = useRegion().financial.currency.symbol;

  const breakdown = useMemo(() => {
    const amountLeft = (
      <Body small light normal regular style={{ marginTop: 8 }}>
        Amount assigned to next tier is: {currencySymbol}
        {formatCurrency(data.availableCommission, 2)} - {currencySymbol}
        {formatCurrency(data.allocated, 2)} = {currencySymbol}
        {formatCurrency(
          Number(data.availableCommission) - Number(data.allocated),
          2
        )}
      </Body>
    );
    const endOfRange = Number(data.sliding_structure_tier.end_of_range);
    const lastTier = index === length - 1;

    if (['gross', 'gross_less_referral', 'net'].includes(calculationId)) {
      const amountInTier = Number(data.allocated) + Number(data.runningTotal);

      if (!lastTier) {
        return (
          <>
            <Body small light normal regular style={{ marginTop: 4 }}>
              (Allocated tier amount = {currencySymbol}
              {formatCurrency(data.allocated, 2)}) + (contributions to date ={' '}
              {currencySymbol}
              {formatCurrency(data.runningTotal, 2)}) = {currencySymbol}
              {formatCurrency(amountInTier, 2)}{' '}
              {amountInTier >= endOfRange
                ? 'which equals max amount for this tier'
                : 'fits within this tier'}
            </Body>
            {amountLeft}
          </>
        );
      }
      return (
        <Body small light normal regular style={{ marginTop: 4 }}>
          (Remaining {currencySymbol}
          {formatCurrency(data.availableCommission, 2)}) + (Contributions to
          date {currencySymbol}
          {formatCurrency(data.runningTotal, 2)}) = {currencySymbol}
          {formatCurrency(
            Number(data.availableCommission) + Number(data.runningTotal),
            2
          )}{' '}
          fits within this tier
        </Body>
      );
    }

    const amountInTier = Number(data.allocated) + Number(data.runningTotal);
    return (
      <>
        <Body small light normal regular style={{ marginTop: 4 }}>
          ({Number(data.percent)}% of {currencySymbol}
          {formatCurrency(data.allocated, 2)} = {currencySymbol}
          {formatCurrency(data.amount, 2)}) + (earnings to date ={' '}
          {currencySymbol}
          {data.runningTotal}) = {currencySymbol}
          {formatCurrency(
            Number(data.amount) + Number(data.runningTotal),
            2
          )}{' '}
          {amountInTier >= endOfRange
            ? 'which equals max amount for this tier'
            : 'fits within this tier'}
        </Body>
        {!lastTier && amountLeft}
      </>
    );
  }, [data, currencySymbol, calculationId, index, length]);

  return (
    <DefaultCell
      {...rest}
      value={
        <Box>
          <Body dark normal>
            {currencySymbol}
            {formatCurrency(data.sliding_structure_tier.start_of_range, 2)}{' '}
            {Number(data.sliding_structure_tier?.end_of_range)
              ? `- ${currencySymbol}${formatCurrency(
                  data.sliding_structure_tier.end_of_range,
                  2
                )}`
              : 'and over'}
          </Body>
          {showBreakdown && breakdown}
        </Box>
      }
    />
  );
}

export default TierRangeCell;
