import Box from '@rexlabs/box';
import ToggleButton from 'components/button/toggle-button';
import { Body } from 'components/text/body';
import React from 'react';
import PaddingBox from 'src/view/components/padding-box';
import { StatusCircle } from 'src/view/components/status-circle';
import { ListingPerformance, MarketingCampaign } from '../data/performance';
import { Column, Grid } from 'shared/components/grid';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import { SubHeading } from 'components/text/sub-heading';
import dayjs from 'dayjs';

// Body uses inline styling that is why important is needed
const styles = StyleSheet({
  count: {
    fontSize: '27px !important'
  },
  label: {
    lineHeight: '17px !important'
  }
});

interface PrimaryStatsProps {
  range: string;
  setRange: (id: string) => void;
  selectedPortal: MarketingCampaign;
}

const PrimaryStats = ({
  range,
  setRange,
  selectedPortal
}: PrimaryStatsProps) => {
  const s = useStyles(styles);

  return (
    <PaddingBox white padding={'14px 25px'}>
      <Box justifyContent='space-between' mb={14}>
        <div>
          <SubHeading semibold>Listing Performance</SubHeading>
          <Box alignItems='center'>
            <StatusCircle green />
            <Body dark normal>
              Last updated:{' '}
              {dayjs(selectedPortal.last_updated).format('DD MMMM YYYY')}
            </Body>
          </Box>
        </div>
        <ToggleButton
          options={[
            {
              id: 'total',
              text: 'Total Campaign'
            },
            {
              id: '7days',
              text: 'Last 7 days'
            },
            {
              id: '30days',
              text: 'Last 30 days'
            }
          ]}
          selected={range}
          setSelected={setRange}
        />
      </Box>
      <Grid columns={selectedPortal.primary_stats.length} m={'0 -5px'}>
        {selectedPortal.primary_stats.map((stat) => (
          <Column width={1} p={'0 5px'} key={stat.id}>
            <PaddingBox
              light
              padding={'15px 15px 18px'}
              height={99}
              flexDirection='column'
              justifyContent='space-between'
              style={{ borderRadius: 4 }}
            >
              <Body semibold dark {...s('label')}>
                {stat.label}
              </Body>
              <Body semibold dark {...s('count')}>
                {stat.value}
              </Body>
            </PaddingBox>
          </Column>
        ))}
      </Grid>
    </PaddingBox>
  );
};

export default PrimaryStats;
