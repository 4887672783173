import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import types from 'prop-types';
import Box from '@rexlabs/box';

import { ButtonBar } from 'view/components/button-bar';
import Dialog from 'view/components/dialog';
import { TextButton, DefaultButton } from 'view/components/button';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { DialogBody as Body } from 'components/text/dialog-body';

import _ from 'lodash';

@withErrorDialog
@autobind
class ConfirmationDialog extends PureComponent {
  static propTypes = {
    title: types.string,
    cancelText: types.string,
    confirmText: types.string,
    onConfirm: types.func.isRequired,
    onCancel: types.func,
    hideCancel: types.bool,
    hideError: types.bool
  };

  static defaultProps = {
    title: 'Confirm',
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    message: 'Are you sure?',
    buttonColor: 'red',
    top: Math.max(window.innerHeight / 2 - 200, 0),
    width: 500
  };

  state = {
    isLoading: false
  };

  handleCancel() {
    const { onCancel = _.noop, closeDialog, errorDialog } = this.props;
    this.setState({ isLoading: true }, () => {
      Promise.resolve()
        .then(onCancel)
        .then(closeDialog)
        .catch((e) => {
          this.setState({ isLoading: false });
          errorDialog.open(e);
        });
    });
  }

  handleConfirm() {
    const {
      onConfirm = _.noop,
      closeDialog,
      errorDialog,
      hideError
    } = this.props;
    this.setState({ isLoading: true }, () => {
      Promise.resolve()
        .then(onConfirm)
        .then(closeDialog)
        .catch((e) => {
          this.setState({ isLoading: false });
          !hideError && errorDialog.open(e);
        });
    });
  }

  render() {
    const {
      title,
      cancelText,
      confirmText,
      buttonColor,

      closeDialog,
      onLoad,
      isLoading,
      children,
      message,
      top,
      width,
      hideCancel
    } = this.props;

    return (
      <Dialog
        title={title}
        width={width}
        closeDialog={closeDialog}
        onLoad={onLoad}
        top={top}
      >
        <Box>
          {children ? (
            _.isString(children) ? (
              <Body>{children}</Body>
            ) : (
              <Box>{children}</Box>
            )
          ) : (
            <Body dark>{message}</Body>
          )}
        </Box>

        <ButtonBar isLoading={isLoading || this.state.isLoading}>
          {!hideCancel && (
            <TextButton key='cancel' blue onClick={this.handleCancel}>
              {cancelText}
            </TextButton>
          )}
          <DefaultButton
            key='confirm'
            {...{ [buttonColor]: true }}
            onClick={this.handleConfirm}
          >
            {confirmText}
          </DefaultButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;
