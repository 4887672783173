import Box from '@rexlabs/box';
import { compose } from 'redux';
import { useModelActions, withModel } from '@rexlabs/model-generator';
import { Body } from 'components/text/body';
import adminChartOfAccountsModel from 'features/finance/data/chart-of-accounts';
import {
  AdminChartOfAccountsModel,
  ChartOfAccount
} from 'features/finance/types/chart-of-accounts';
import { useErrorDialog } from 'hooks/use-error-dialog';
import React, { useEffect, useRef, useState } from 'react';
import { PADDINGS } from 'src/theme';
import { Select } from 'src/view/components/input/select';
import { Form, FormField, ReactForms } from 'view/components/form';
import accountSettingsDefaultAccountsModel from 'features/finance/data/account-defaults';
import {
  AccountSettings,
  AccountSettingsDefaultAccountsModel
} from 'features/finance/types/account-defaults';
import useShouldUpdateChildAccounts from 'features/settings/hooks/use-should-update-child-accounts';
import SaveCancelOverlayHandler from 'src/view/components/save-cancel-overlay-handler';
import { SetFieldValue } from '@rexlabs/form';
import uiModel from 'data/models/custom/ui';
import { useWhichWordFactory } from 'hooks/use-which-word';

interface AdminAccountDefaultsProps {
  adminChartOfAccounts: AdminChartOfAccountsModel;
  accountSettingsDefaultAccounts: AccountSettingsDefaultAccountsModel;
}

interface AccountItem {
  label: string;
  value: string;
  data: ChartOfAccount;
}

const AdminAccountDefaults = ({
  adminChartOfAccounts,
  accountSettingsDefaultAccounts
}: AdminAccountDefaultsProps) => {
  const { loadingIndicatorOn, loadingIndicatorOff } = useModelActions(
    uiModel
  ) as any;
  const { updateSettings, getEffectiveSettings } =
    accountSettingsDefaultAccounts;
  const setFieldValueRef = useRef<SetFieldValue<string | null> | null>(null);
  const errorDialog = useErrorDialog();
  const shouldUpdateChildAccounts = useShouldUpdateChildAccounts();
  const ww = useWhichWordFactory();
  const [accounts, setAccounts] = useState<Array<AccountItem>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultSettings, setDefaultSettings] =
    useState<AccountSettings | null>(null);

  const onSubmit = async (values: AccountSettings, { resetForm }) => {
    try {
      const updateChildAccounts = await shouldUpdateChildAccounts();

      if (updateChildAccounts.didCancel) {
        resetForm();
        return;
      }

      await updateSettings({
        settings: {
          agency_share_commission_account:
            values?.agency_share_commission_account || null,
          payable_commission_account:
            values?.payable_commission_account || null,
          payable_commission_tax_free_account:
            values?.payable_commission_tax_free_account || null,
          vpa_advertising_product_account:
            values?.vpa_advertising_product_account || null
        },
        update_child_accounts: !!updateChildAccounts.result
      });
    } catch (error) {
      errorDialog.open(error as Error);
    }
  };

  useEffect(() => {
    const getDefaultSettings = async () => {
      loadingIndicatorOn({ message: 'Loading data...' });
      try {
        const res = await getEffectiveSettings();
        setDefaultSettings(res.data.result);
      } catch (error) {
        errorDialog.open(error as Error);
      }
      loadingIndicatorOff();
    };
    getDefaultSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getConditions = async () => {
      setIsLoading(true);
      try {
        const res = await adminChartOfAccounts.fetchList({
          args: {
            criteria: [
              {
                name: 'is_hidden',
                type: '=',
                value: '0'
              }
            ],
            limit: 100
          }
        });
        setAccounts(
          res.data.map((account) => ({
            value: account.item.id.toString(),
            label: account.item.name,
            data: account.item
          }))
        );
      } catch (error) {
        errorDialog.open(error as Error);
      }
      setIsLoading(false);
    };

    getConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!defaultSettings) return null;

  return (
    <Box p={PADDINGS.M} m='0px' spacing={PADDINGS.S}>
      <Body semibold dark large>
        Default Accounts
      </Body>
      <Body regular dark style={{ marginBottom: 30 }}>
        Specify a default account for the following line items which are
        required for Commissions and VPA invoicing. You will need to set up your
        chart of accounts first before mapping your default accounts. Once
        configured, invoices will have default accounts mapped to these line
        items which can be used for any external accounting integrations.
      </Body>
      <ReactForms handleSubmit={onSubmit} initialValues={defaultSettings}>
        {({ resetForm, submitForm, isDirty, setFieldValue }) => {
          setFieldValueRef.current = setFieldValue;
          return (
            <Form>
              <Body semibold dark large style={{ marginBottom: 7.5 }}>
                Commission Invoices
              </Body>
              <Body regular dark style={{ marginBottom: 20 }}>
                For Commission Invoices, the following line items must be mapped
                to a default account. In the commission worksheet, the ‘net
                office commission’ line item is the agency share commission and
                the ‘salesperson’s distributable commission’ line item is the
                payable commission.
              </Body>
              <Box width={'50%'}>
                <FormField
                  label='agency share commission'
                  name='agency_share_commission_account'
                  Input={Select}
                  inputProps={{
                    searchOnMount: true,
                    options: accounts,
                    isLoading
                  }}
                />
                <FormField
                  label='payable commission'
                  name='payable_commission_account'
                  Input={Select}
                  inputProps={{
                    searchOnMount: true,
                    options: accounts,
                    isLoading
                  }}
                />
                <FormField
                  label={`payable commission (${ww('GST')} Free)`}
                  name='payable_commission_tax_free_account'
                  Input={Select}
                  inputProps={{
                    searchOnMount: true,
                    options: accounts,
                    isLoading
                  }}
                />
              </Box>
              <Body
                semibold
                dark
                large
                style={{ marginBottom: 7.5, marginTop: 30 }}
              >
                VPA Invoices
              </Body>
              <Body regular dark style={{ marginBottom: 20 }}>
                For VPA Invoices, advertising products will be mapped to this
                default account. You can still edit individual products under
                Settings &gt; Advert Providers & Packs.
              </Body>
              <Box width={'50%'}>
                <FormField
                  label='advertising products'
                  name='vpa_advertising_product_account'
                  Input={Select}
                  inputProps={{
                    searchOnMount: true,
                    options: accounts,
                    isLoading
                  }}
                />
              </Box>
              <SaveCancelOverlayHandler
                isVisible={isDirty}
                onSave={submitForm}
                onCancel={resetForm}
              />
            </Form>
          );
        }}
      </ReactForms>
    </Box>
  );
};

export default compose(
  withModel(adminChartOfAccountsModel),
  // eslint-disable-next-line
  // @ts-ignore
  withModel(accountSettingsDefaultAccountsModel)
)(AdminAccountDefaults);
