import React, { useState, useEffect } from 'react';
import SplitValueInput from 'view/components/input/split-value';
import Select from 'view/components/input/select/select';
import { StyleSheet, StylesProvider } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS } from 'theme';

const getSplitInputOverrides = _.memoize((disabled) => ({
  TextInput: StyleSheet({
    cosmeticWrapper: {
      padding: '0'
    },
    input: {
      height: '100% !important',
      maxHeight: '100% !important'
    }
  }),
  SplitValueInput: StyleSheet({
    inputContainer: {
      width: '100%',
      backgroundColor: disabled ? '#E6E5E3' : 'none',
      borderRadius: 0,
      boxSizing: 'border-box',
      border: `2px solid ${COLORS.INPUT.BORDER}`,
      '&:hover': {
        borderColor: COLORS.INPUT.BORDER_ACTIVE
      },
      '&:focus-within': {
        outline: `2px solid ${COLORS.INPUT.OUTLINE}`,
        borderColor: COLORS.INPUT.BORDER_ACTIVE,
        boxShadow: 'none'
      }
    },
    divider: {
      height: '1.6rem'
    }
  }),
  SelectMenu: StyleSheet({
    container: {
      height: '100px !important'
    }
  })
}));

const selectInputOverrides = {
  TextInput: StyleSheet({
    container: {
      border: 'none',
      height: 'auto',
      minHeight: 'auto',
      '&:focus-within': {
        outline: 'none'
      }
    },
    input: {
      border: 'none',
      padding: 0
    }
  })
};

const MONTH_OPTIONS = [
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

const getDaysInMonth = (month) => {
  if (!month) return 0;
  const year = new Date().getFullYear();
  return new Date(year, month, 0).getDate();
};

function DayMonthInput(props) {
  const { value = {}, onChange, disabled } = props;

  const dayOptions = Array.from(
    { length: getDaysInMonth(value.month) },
    (_, i) => ({ value: `${i + 1}`, label: String(i + 1) })
  );

  return (
    <StylesProvider
      components={getSplitInputOverrides(props.disabled)}
      prioritiseParentStyles={false}
    >
      <SplitValueInput
        {...props}
        LeftInput={(props) => (
          <StylesProvider
            components={selectInputOverrides}
            prioritiseParentStyles={false}
          >
            <Select
              {...props}
              options={dayOptions}
              placeholder='Select Day'
              value={value.day}
              onChange={(e) =>
                onChange({ day: e.target.value, month: value.month })
              }
              disabled={false}
            />
          </StylesProvider>
        )}
        RightInput={(props) => (
          <StylesProvider
            components={selectInputOverrides}
            prioritiseParentStyles={false}
          >
            <Select
              {...props}
              options={MONTH_OPTIONS}
              placeholder='Select Month'
              value={value.month}
              onChange={(e) =>
                onChange({ day: value.day, month: e.target.value })
              }
              disabled={disabled}
            />
          </StylesProvider>
        )}
        value={{
          left: value.day,
          right: value.month
        }}
      />
    </StylesProvider>
  );
}

export default DayMonthInput;
