import { Body } from 'components/text/body';
import React from 'react';
import Icon, { ICONS } from 'shared/components/icon';
import { DefaultButton } from 'src/view/components/button';
import PaddingBox from 'src/view/components/padding-box';
import { CommissionWorksheetItem } from '../types/commission-worksheet';
import { useDialog } from 'hooks/use-dialog';
import dayjs from 'dayjs';
import { usePermissions } from 'hooks/use-permissions';
import Spinner from 'shared/components/spinner';

interface LockWorksheetFooterProps {
  worksheet: CommissionWorksheetItem;
  lockWorksheet: () => void;
  unlockWorksheet: () => Promise<void>;
}

const LockWorksheetFooter = ({
  worksheet,
  lockWorksheet,
  unlockWorksheet
}: LockWorksheetFooterProps) => {
  const lockWorksheetDialog = useDialog('lockWorksheetDialog');
  const [isLoading, setIsLoading] = React.useState(false);

  const { check } = usePermissions();
  const canLockWorksheet = check('listing_commissions.lock_commissions');
  const canUnlockWorksheet = check('listing_commissions.unlock_commissions');

  return (
    <PaddingBox
      light
      flexDirection='row'
      justifyContent='flex-end'
      alignItems='center'
      spacing={25}
    >
      <Body dark regular>
        {worksheet.is_locked && worksheet.locked_by_user
          ? `Worksheet locked by ${worksheet.locked_by_user._name} ${dayjs(
              worksheet.locked_at
            ).format('D MMM YYYY h:m A')}`
          : 'Lock this commission worksheet to prevent any further changes'}
      </Body>
      <DefaultButton
        dark
        IconLeft={() => (
          <Icon
            type={worksheet.is_locked ? ICONS.PADLOCK_OPEN : ICONS.PADLOCK}
            style={{ display: 'flex', width: 18, marginLeft: 5 }}
          />
        )}
        onClick={() => {
          if (worksheet.is_locked) {
            setIsLoading(true);
            unlockWorksheet().then(() => setIsLoading(false));
          } else {
            lockWorksheetDialog.open({
              worksheet,
              lockWorksheet
            });
          }
        }}
        isLoading={isLoading}
        Loading={() => <Spinner centered small classic />}
        isDisabled={
          (worksheet.is_locked && !canUnlockWorksheet) ||
          (!worksheet.is_locked && !canLockWorksheet)
        }
      >
        {worksheet.is_locked ? 'Unlock Worksheet' : 'Lock Worksheet'}
      </DefaultButton>
    </PaddingBox>
  );
};

export default LockWorksheetFooter;
