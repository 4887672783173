/*
|-------------------------------------------------------------------------------
| API Client
|-------------------------------------------------------------------------------
|
| The singleton API client is created with @rexlabs/api-client. It includes:
|  - Base URL sourced from the env config
|  - Default headers for laravel servers
|  - Middleware (interceptors) for common tasks
|
*/

// Note when upgrading @rexlabs/api-client: we use CancelToken in graphQL requests
// which is deprecated in later versions of axios. When upgrading the api-client package
// switch to AbortController to ensure it keeps working https://axios-http.com/docs/cancellation
// Relevant file is shell/src/hooks/use-graphql-query.tsx

import _ from 'lodash';
import { create } from '@rexlabs/api-client';
import config from 'shared/utils/config';

import Analytics from 'shared/utils/vivid-analytics';

import { getRedirectToAuthService } from './redirect';
import { parseDirtyBoolean, parseUserPrivilegesResponse } from './parse';
import { apiFake } from './fake';
import {
  ETAG_LIMIT,
  PAGE_LIMIT,
  transformAutocomplete,
  transformAutocompleteArgs,
  transformFormValuesToObjects,
  transformItem,
  transformItemArgs,
  transformList,
  transformListArgs,
  transformStream,
  transformStreamArgs,
  transformUpdateData,
  transformValueList
} from './transform';

const TOKEN_EXPIRED_TYPE = 'TokenException';
const APP_IDS = { REX: 'rex', GLOBAL: 'global' };

const clientConfig = {
  baseURL: config.API_URL
};

const api = create(clientConfig);
api.setHeader('Accept', 'application/json');
api.setHeader('Content-Type', 'application/json');

// Pass further options through the header
const options = {
  // use_status_codes: true
  // add_request_prefixes: true,
  // use_strict_arguments: false,
  // strip_response_prefixes: false
};

const xOptions = [];
for (const key in options) {
  xOptions.push(key + '=' + options[key]);
}
api.setHeader('x-api-option', xOptions);

api.redirect = true;
api.setRedirect = (redirectMe) => {
  api.redirect = redirectMe;
};

api.appId = 'rex';
api.setApp = (newAppId) => {
  api.appId = newAppId;

  if (newAppId === 'rexgroup') {
    api.setHeader('X-App-Identifier', 'Rexlabs:Rex:GroupApp');
  } else {
    api.setHeader('X-App-Identifier', 'Rexlabs:Rex:App');
  }
};

// Authentication via header
api.setAuthToken = (apiToken) => {
  api.setHeader('authorization', `Bearer ${apiToken}`);
};

// Change base url, whenever we get a cluser from the global API!
const urlBase = api.axiosInstance.defaults.baseURL;
api.setBaseUrl = (baseURL) => {
  api.axiosInstance.defaults.baseURL = baseURL;
};

api.getRedirectUrl = getRedirectToAuthService;
api.redirectToLogin = () => {
  if (config.AUTHENTICATION_SERVICE_FRONTEND_URL) {
    const { redirectUrl } = api.getRedirectUrl(api.appId);
    window.parent.location.href = redirectUrl;
  } else {
    console.error(
      "This should've redirected, check your `config.AUTHENTICATION_SERVICE_FRONTEND_URL` value is set!"
    );
    Analytics.error({
      error: new Error(
        "This should've redirected, check your `config.AUTHENTICATION_SERVICE_FRONTEND_URL` value is set!"
      )
    });
  }
};

// Handle public screens having potentially different region requirements
api.addRequestInterceptor(async (request) => {
  let regionBase = null;
  const globalAuthUrls = [
    'Authentication::getAccessibleAccounts',
    'Authentication::getLoginFlowUrl',
    'Authentication::getSupportedLoginMethods',
    'Authentication::getTokenClaims',
    'Authentication::login',
    'Authentication::exchangeLoginTokenForRexToken'
    // 'Authentication::resetPassword', // Uncomment when Alex puts this endpoint on global
  ];

  if (window.location.href.includes('region=')) {
    regionBase = await getRegionBase(getRegionFromUrl());
    request.baseURL = getBaseUrl(undefined, regionBase);
  }

  // The map is required because there is a /rex/ and /global/ Authentication service name
  if (
    globalAuthUrls.includes(request.url) ||
    request.url.includes('Region::')
  ) {
    request.baseURL = getBaseUrl(APP_IDS.GLOBAL, regionBase);
    request.headers['X-App-Identifier'] = 'Rexlabs:Rex:Auth';
  } else if (request.url.includes('Authentication::resetPassword')) {
    // Remove when Alex puts this endpoint on global
    request.baseURL = getBaseUrl(APP_IDS.REX, regionBase);
  }

  return request;
});

const UNDER_MAINTENANCE_REGEXP = new RegExp('under maintenance', 'i');

// List of endpoints and status codes that we want to not run the interceptor on
// Leave status codes array empty to mute ALL statuses
const MUTED_ENDPOINTS = [
  { endpoint: 'Announcements::search', statusCodes: [] }
];

// Handle API errors
api.addResponseInterceptor((response) => {
  const isOnline = navigator ? navigator.onLine : undefined;
  const isMaintenance =
    response.status === 503 && UNDER_MAINTENANCE_REGEXP.test(response.data);

  if (isMaintenance) {
    const retryAfter = _.get(response, 'headers.retry-after');

    let appId = 'rex';
    window.parent.location.search
      .replace('?', '')
      .split('&')
      .forEach((query) => {
        const [key, value = null] = query.split('=');
        if (key === 'app_id') {
          appId = value;
        }
      });

    window.parent.location.href =
      `${config.AUTH_APP_URL}/public/maintenance?app_id=${appId}` +
      `${retryAfter ? `&date=${retryAfter}` : ''}`;
    return response;
  }

  // We want to report API errors to bugsnag to be able to analyse and
  // API issues, we're adding a bunch of meta data here to be able to
  // filter by certain things
  // We also want to filter out 401 (token expired) and
  // other unimportant errors

  if (
    response.status &&
    (response.status >= 300 || response.status < 200) &&
    ![401, 422, 400].includes(response.status) &&
    !['NETWORK_ERROR'].includes(response.problem) &&
    !MUTED_ENDPOINTS.find(
      (muteConfig) =>
        response?.config?.url?.includes(muteConfig.endpoint) &&
        (muteConfig.statusCodes.length === 0 ||
          muteConfig.statusCodes.includes(response?.status))
    )
  ) {
    // TODO: message isn't being passed to analytics
    //  https://app.clubhouse.io/rexlabs/story/52633/bug-api-error-message-not-being-logged-in-api-client
    // const message =
    //   _.get(response, 'data.error.display_message') ||
    //   _.get(response, 'data.error.message') ||
    //   'Unknown error';

    const status = response.status;
    const statusRange = `${status.toString()[0]}xx`;

    const requestUrl = _.get(response, 'config.url');
    const requestMethod = _.get(response, 'config.method');
    const requestHeaders = {
      ..._.get(response, 'config.headers', {}),
      authorization: '-'
    };
    const requestData = _.get(response, 'config.data');
    const responseData = _.get(response, 'data');
    const responseProblem = _.get(response, 'problem');

    Analytics.error({
      error: new Error(`${statusRange} API Error: ${requestUrl}`),
      properties: {
        metaData: {
          apiStatus: {
            status,
            range: statusRange,
            online: isOnline
          },
          apiRequest: {
            url: requestUrl,
            method: requestMethod,
            headers: requestHeaders,
            data: requestData
          },
          apiResponse: {
            data: responseData,
            problem: responseProblem
          }
        }
      }
    });
  }

  if (response.problem || (response.data && response.data.error)) {
    if (_.get(response, 'data.error.type') === TOKEN_EXPIRED_TYPE) {
      // Log out when getting token expired response
      // NOTE: not using response.status here, since we get 401 for a couple
      // of things, including missing permissions etc, which should not cause
      // the user to get logged out!
      if (api.redirect) {
        api.redirectToLogin();
        return response;
      } else {
        throw new Error('Not authorised');
      }
    } else if (response.problem === 'NETWORK_ERROR') {
      const networkError = new Error(
        'Network error. Please check your connection.'
      );
      networkError.problem = 'NETWORK_ERROR';
      throw networkError;
    } else if (
      _.get(response, 'data.error') &&
      (response.data.error.type === 'UserValidationException' ||
        response.data.error.type === 'MetricInputsRequiredException')
    ) {
      return response;
    } else if (response.problem === 'CANCEL_ERROR') {
      throw response;
    }
    const message =
      _.get(response, 'data.error.display_message') ||
      _.get(response, 'data.error.message') ||
      'Unknown request failure';

    const error = new Error(message);
    error.problem =
      _.get(response, 'data.error.type') || _.get(response, 'problem');

    if (__DEV__) {
      console.warn('API Error:\n\n', error);
    }
    throw error;
  }
  return response;
});

// This has been done to allow a custom validation issue dialog to resolve the
// api request's promise to the parent dialog
const originalPost = api.post;
api.post = (...args) =>
  new Promise((resolve, reject) => {
    originalPost(...args)
      .then((res) => {
        const triggers = _.get(res, 'data.triggers');

        if (window.Shell && window.Rex2FrameWindow && triggers) {
          triggers.forEach((trigger) => {
            if (trigger.type === 'opt_in_workflow') {
              const {
                required_input: requiredInput,
                description,
                name,
                request
              } = trigger;

              window.Rex2FrameWindow.AVM.shell.dialogs.confirmWorkflow.open({
                context: _.get(requiredInput, 'context'),
                formSchema: _.get(requiredInput, 'form'),
                onSubmit: (formValues) => {
                  const error = new Error();
                  return new Promise((resolve, reject) => {
                    const requestParams = _.get(request, 'params');
                    const requestParamsData = _.get(requestParams, 'data');
                    const endpoint = _.get(request, 'endpoint');

                    if (endpoint) {
                      // Strip the rex prefix cause we are inside of Rex 😉
                      const requestEndpoint = endpoint.replace('/v1/rex/', '');
                      const requestBody = {
                        ...requestParams,
                        data: {
                          ...requestParamsData,
                          context: _.get(requiredInput, 'context'),
                          form_submission:
                            transformFormValuesToObjects(formValues)
                        }
                      };

                      api
                        .post(requestEndpoint, requestBody)
                        .then(resolve)
                        .catch(reject);
                    } else {
                      error.message = 'No endpoint specified.';
                      reject(error);
                    }
                  });
                },
                workflowName: name,
                workflowDescription: description
              });
            }
          });
        }

        if (
          window.Shell &&
          _.get(res, 'data.error.type') === 'UserValidationException'
        ) {
          _.get(args, '1.data')['::ignore_user_validation_warnings'] = true;
          window.Shell.Bridges.Dialogs.customValidationIssue.open({
            customMessage: _.get(res, 'data.error.message'),
            suggestedWorkflow: _.get(
              res,
              'data.error.extra.suggested_workflow'
            ),
            isWarning: _.get(res, 'data.error.extra.is_warning'),
            onSave: () => {
              return originalPost(...args)
                .then((res) => {
                  resolve(res);
                })
                .catch(reject);
            },
            onCancel: () => {
              const error = new Error(
                _.get(res, 'data.error.display_message') ||
                  _.get(res, 'data.error.message')
              );
              error.problem = _.get(res, 'data.error.type');
              reject(error);
            }
          });
        } else if (
          window.Shell &&
          _.get(res, 'data.error.type') === 'MetricInputsRequiredException'
        ) {
          window.Shell.Bridges.Dialogs.metricInput.open({
            data: _.get(res, 'data.error.extra'),
            onSave: (values) => {
              _.get(args, '1.data')['::metric_inputs'] = values;
              return originalPost(...args)
                .then((res) => {
                  resolve(res);
                })
                .catch(reject);
            },
            onCancel: () => {
              const error = new Error(
                _.get(res, 'data.error.display_message') ||
                  _.get(res, 'data.error.message')
              );
              error.problem = _.get(res, 'data.error.type');
              reject(error);
            }
          });
        } else {
          resolve(res);
        }
      })
      .catch(reject);
  });

/**
 * Helper for making a BatchRequests::execute call to Wings API.
 *
 * A batch request sounds exactly as the name suggests. It lets you batch a group
 * of API calls together. This improves response times as the server can quickly return
 * a chunk of data instead of trying to make 5 separate requests and then returning them
 * 1 at a time.
 *
 * This helper accepts 2 different formats of requests.
 *
 * 1. Array Structure
 *
 * requests = [
 *    [
 *      'Contracts::search',
 *      {
 *        criteria: { [{name: "listing_id", value: 1752252}] }
 *      }
 *    ]
 * ]
 *
 * 2. Object Structure
 *
 * requests = {
 *    Contracts: [
 *      'Contracts::search',
 *      {
 *        criteria: { [{name: "listing_id", value: 1752252}] }
 *      }
 *    ]
 * }
 *
 * Using the object structure can help with referencing results after the API resolves a response.
 *
 * The second parameter is a bool that changes the Batch service method to `executeWithTransaction`
 * which when true will cause all database updates to fail if any request fails.
 *
 * Note that regardless of execute vs executeWithTransaction, if one request fails,
 * the whole batch request will fail.
 *
 */
api.batch = (requests, executeWithTransaction) => {
  const batchObjectRequests = () => {
    const requestsObj = {};

    for (const key in requests) {
      if (requests[key]) {
        const req = requests[key];

        requestsObj[key] = _.isArray(req)
          ? { method: req[0], args: req[1] }
          : { method: req };
      }
    }

    return requestsObj;
  };
  const batchArrayRequests = () => {
    return requests.map((req) => {
      if (!_.isArray(req)) {
        return { method: req };
      }
      return { method: req[0], args: req[1] };
    });
  };

  const mappedRequests = _.isArray(requests)
    ? batchArrayRequests()
    : batchObjectRequests();

  return api.post(
    `BatchRequests::${
      executeWithTransaction ? 'executeWithTransaction' : 'execute'
    }`,
    { requests: mappedRequests }
  );
};

api.fetchAll = async (endpoint, args, options) => {
  const limit = _.get(args, 'limit') || 20;
  const firstPage = await api.post(
    endpoint,
    {
      ...args,
      limit,
      offset: 0
    },
    options
  );
  const total = firstPage.data.result.total;

  let lastOffset = 0;
  let result = { rows: firstPage.data.result.rows || firstPage.data.result };

  // If total exists we know how many requests we have to make
  // so we can make them all in parallel instead of in sequence
  if (total) {
    const requests = [];

    // i is just a safety net, so we don't run into infinite loops
    // for whatever reason
    let i = 0;
    while (lastOffset + limit < total && i < 1000) {
      requests.push(
        api.post(endpoint, {
          ...args,
          limit,
          offset: lastOffset + limit
        })
      );
      lastOffset = lastOffset + limit;
      i++;
    }

    const responses = await Promise.all(requests);

    result = responses.reduce((acc, response) => {
      acc.rows = [...acc.rows, ...response.data.result.rows];
      return acc;
    }, result);
  } else {
    let lastResult = result.rows;

    // i is just a safety net, so we don't run into infinite loops
    // for whatever reason
    let i = 0;
    while (lastResult && lastResult.length === limit && i < 1000) {
      const nextPage = await api.post(endpoint, {
        ...args,
        limit,
        offset: lastOffset + limit
      });

      lastResult = nextPage.data.result;
      result.rows = [...result.rows, ...lastResult];
      lastOffset = lastOffset + limit;

      i++;
    }
  }

  return result;
};

/**
 * Get the region hash parameter from the current URL.
 */
api.getRegionFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.hash);
  return urlParams.get('region');
};

/**
 * This will request Region data. It takes a region hash value and returns the base URL that corresponds to that region.
 * @param {string} regionURL - Must be viable region hash param. If provided, request region bases and provide the corresponding base for the region.
 */
api.getRegionBase = (regionFromUrl) => {
  return fetch(`${urlBase}/Region::getRegions`, { method: 'GET' })
    .then(function (response) {
      return response.json();
    })
    .then(function (myJson) {
      const regions = myJson.result;
      return _.get(regions, `${regionFromUrl}.base_url`);
    })
    .catch((err) => console.error(err));
};

/**
 * This will provide a URL with the specified appId and region URL base.
 * Returns a new base URL with the desired appId and region.
 * @param {string} newAppId - App ID to use (e.g. rex or rexgroup). Defaults to current app id.
 * @param {string} regionBase - region base URL as provided by getRegionBase
 */
api.getBaseUrl = (newAppId, regionBase) => {
  const shortBase = urlBase.substring(0, urlBase.indexOf('/v1/'));
  const base = regionBase || shortBase;
  return `${base}/v1/${newAppId || api.appId}`;
};

const setRedirect = api.setRedirect;
const setAuthToken = api.setAuthToken;
const setBaseUrl = api.setBaseUrl;
const getBaseUrl = api.getBaseUrl;
const getRegionBase = api.getRegionBase;
const getRegionFromUrl = api.getRegionFromUrl;

export {
  api,
  setRedirect,
  setAuthToken,
  setBaseUrl,
  parseDirtyBoolean,
  parseUserPrivilegesResponse,
  transformList,
  transformStream,
  transformItem,
  transformAutocomplete,
  transformValueList,
  transformUpdateData,
  transformListArgs,
  transformStreamArgs,
  transformItemArgs,
  transformAutocompleteArgs,
  transformFormValuesToObjects,
  PAGE_LIMIT,
  ETAG_LIMIT,
  getRegionFromUrl,
  getRegionBase,
  getBaseUrl,
  apiFake
};
