import React, { useState, useEffect } from 'react';
import PortalSelect from './components/portal-select';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';
import PrimaryStats from './components/primary-stats';
import portalStatsModel, {
  GetListingStatsPayload,
  ListingPerformance,
  MarketingCampaign,
  PortalStatsModel
} from './data/performance';
import SecondaryStats from './components/secondary-stats';
import EngagementStats from './components/engagement-stats';
import { useModelActions } from '@rexlabs/model-generator';
import { useErrorDialog } from 'hooks/use-error-dialog';
import PaddingBox from 'src/view/components/padding-box';
import Spinner from 'shared/components/spinner';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import dayjs from 'dayjs';

const styles = StyleSheet({
  empty: {
    fontSize: '17px',
    fontWeight: 600,
    fontStyle: 'italic',
    color: COLORS.PRIMARY.INFORMATIVE,
    textAlign: 'center'
  }
});

interface PortalStatsProps {
  listing_id: string;
}

const PortalStats = ({ listing_id }: PortalStatsProps) => {
  const s = useStyles(styles);
  const errorDialog = useErrorDialog();
  const { getListingStats } = useModelActions(
    portalStatsModel
  ) as unknown as PortalStatsModel;
  const [listingPerformance, setListingPerformance] =
    useState<ListingPerformance | null>(null);
  const [selectedPortal, setSelectedPortal] =
    useState<MarketingCampaign | null>(null);
  const [range, setRange] = useState('total');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getListingStatsAsync = async () => {
      const today = dayjs();
      const payload: GetListingStatsPayload = {
        listing_id
      };

      if (range !== 'total') {
        payload.date_to = today.format('YYYY-MM-DD');
        if (range === '7days') {
          payload.date_from = today.subtract(7, 'day').format('YYYY-MM-DD');
        } else if (range === '30days') {
          payload.date_from = today.subtract(30, 'day').format('YYYY-MM-DD');
        }
      }

      setIsLoading(true);
      try {
        const res = await getListingStats(payload);
        setListingPerformance(res.data.result);
        setSelectedPortal(res.data.result.campaigns[0] ?? null);
      } catch (error) {
        errorDialog.open(error as Error);
      }
      setIsLoading(false);
    };
    getListingStatsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listing_id, range]);

  if (isLoading) {
    return (
      <PaddingBox p={'50px 150px'}>
        <Spinner small centered dark classic />
      </PaddingBox>
    );
  }

  if (!listingPerformance || !selectedPortal)
    return (
      <PaddingBox
        p={'50px 150px'}
        style={{ borderTop: `2px solid ${COLORS.BORDERS.GREY}` }}
      >
        <p {...s('empty')}>
          Currently no performance data available as this listing isn’t on any
          portals
        </p>
      </PaddingBox>
    );

  return (
    <Box pt={20} style={{ borderTop: `2px solid ${COLORS.BORDERS.GREY}` }}>
      <PortalSelect
        listingPerformance={listingPerformance}
        selectedPortal={selectedPortal}
        setSelectedPortal={setSelectedPortal}
      />
      <PrimaryStats
        range={range}
        setRange={setRange}
        selectedPortal={selectedPortal}
      />
      {selectedPortal.engagement_stats &&
        selectedPortal.engagement_stats.length > 0 && (
          <EngagementStats engagementStats={selectedPortal.engagement_stats} />
        )}
      <SecondaryStats selectedPortal={selectedPortal} />
    </Box>
  );
};

export default PortalStats;
