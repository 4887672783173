import { CommissionTier } from '../types/commission-worksheet';

export const getSlidingScaleCommission = (
  commission: number,
  totalAmount: number,
  calculationId: string,
  tiers: CommissionTier[]
) => {
  let availableCommission = commission;
  let runningTotal = totalAmount;
  const result: CommissionTier[] = [];

  if (['gross', 'gross_less_referral', 'net'].includes(calculationId)) {
    for (const tier of tiers) {
      const parsedEndOfRange = Number(tier.sliding_structure_tier.end_of_range);

      if (
        (parsedEndOfRange && runningTotal > parsedEndOfRange) ||
        availableCommission < 1
      )
        continue;

      const amountLeftInTier = !parsedEndOfRange
        ? availableCommission
        : parsedEndOfRange - runningTotal;

      const amountAllocatedToTier =
        amountLeftInTier > availableCommission
          ? availableCommission
          : amountLeftInTier;

      tier.allocated = amountAllocatedToTier;
      tier.runningTotal = runningTotal;
      runningTotal += amountAllocatedToTier;

      tier.amount = amountAllocatedToTier * (Number(tier.percent) / 100);

      tier.availableCommission = availableCommission;
      availableCommission -= amountAllocatedToTier;

      result.push(tier);
    }

    return result;
  }

  // Actual earnings to date calculation
  for (const tier of tiers) {
    const parsedEndOfRange = Number(tier.sliding_structure_tier.end_of_range);

    if (
      (parsedEndOfRange && runningTotal > parsedEndOfRange) ||
      availableCommission < 1
    )
      continue;

    const fullTierCommission =
      availableCommission * (Number(tier.percent) / 100);

    const maxTierCommission = !parsedEndOfRange
      ? fullTierCommission
      : parsedEndOfRange - runningTotal;

    if (fullTierCommission <= maxTierCommission) {
      tier.amount = fullTierCommission;
      tier.runningTotal = runningTotal;
      tier.allocated = availableCommission;
      result.push(tier);
      break;
    }

    tier.amount = maxTierCommission;

    tier.runningTotal = runningTotal;
    runningTotal += maxTierCommission;

    const tierWorksheetCommissionPercent =
      maxTierCommission / fullTierCommission;

    const tierWorksheetCommissionAmount =
      tierWorksheetCommissionPercent * availableCommission;
    tier.allocated = tierWorksheetCommissionAmount;

    tier.availableCommission = availableCommission;
    availableCommission -= tierWorksheetCommissionAmount;

    result.push(tier);
  }

  return result;
};
