import { parseUrlToRoute, parseRouteToUrl, push } from '@rexlabs/whereabouts';
import config from 'shared/utils/config';
import { v4 as uuid } from 'uuid';

const MAX_SAFE_URL_LENGTH = 1000;

const URL_NAMESPACE = 'LongUrl';

// Sometimes our urls are big. How big you ask? like 5000 character plus big.
// SO BIG that NGINX rejects the redirect header on logout. To mitigate this, on logout, if our url is too big,
// we store the url in localstorage keyed by an id, and add that ID to the redirect url.
// then on log in, if there's a urlId present, we restore the url and redirect to that.

function saveLongUrl(url) {
  const urlId = uuid();
  // We use sessionStorage rather than localStorage, because sessionStorage only lasts for the duration of the tab
  // you're on. So sessionStorage naturally cleans up values over time as you leave the app, whereas we would have to
  // manually clean up localStorage values.
  sessionStorage.setItem(`${URL_NAMESPACE}-${urlId}`, url);

  return {
    path: '/',
    hashQuery: {
      urlId
    }
  };
}

export function restoreLongUrl({ urlId }) {
  const longUrl = sessionStorage.getItem(`${URL_NAMESPACE}-${urlId}`);
  if (longUrl) {
    sessionStorage.removeItem(`${URL_NAMESPACE}-${urlId}`);
    push({ config: parseUrlToRoute(longUrl) });
  } else {
    console.error(`Requested urlId "${urlId}" not found`);
  }
}

/**
 * Calculates a URl that directs the user to auth service, which itself contains
 * a redirect URL as a query param
 * @param appId
 * @param shouldLogout
 * @returns {{redirectUrl: string, origin: string, rexRouteObject: *, authRouteObject: *}}
 */
export function getRedirectToAuthService(appId, shouldLogout = false) {
  const rexRouteObject = parseUrlToRoute(window.parent.location.href);

  if (window.parent.location.href.length > MAX_SAFE_URL_LENGTH) {
    rexRouteObject.path = '/';
    rexRouteObject.hashQuery = {
      ...rexRouteObject.hashQuery,
      ...saveLongUrl(window.parent.location.href).hashQuery
    };
  }

  const authRouteObject = parseUrlToRoute(
    config.AUTHENTICATION_SERVICE_FRONTEND_URL
  );
  authRouteObject.query.app_id = appId;

  // Make Rex app IDs compatible with auth service app IDs
  if (appId === 'rex') authRouteObject.query.app_id = 'rex_crm';
  if (appId === 'rexgroup') authRouteObject.query.app_id = 'rex_crm_group_app';

  if (shouldLogout) {
    authRouteObject.query.logout = 1;
  }

  authRouteObject.query.redirect_url =
    window.parent.location.origin + parseRouteToUrl(rexRouteObject);
  authRouteObject.path = '/';

  return {
    rexRouteObject,
    authRouteObject,
    redirectUrl: `${
      config.AUTHENTICATION_SERVICE_FRONTEND_URL
    }${parseRouteToUrl(authRouteObject)}`
  };
}
